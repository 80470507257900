.tabBar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
}

.tab {
  height: 3rem;
  background-color: white;
  color: black;
  border-radius: 6px;
  cursor: pointer;
}

.activeTab {
  background-color: black;
  color: white;
}