.modal_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  width: 600px;
  min-height: 500px;
  background-color: white;
  z-index: 1;
  text-align: center;
  overflow: hidden;
  max-height: 90vh;
  overflow: scroll;
}

.modal > div {
  min-height: 500px;
  /* max-height: 90vh; */
  height: 100%;
  overflow: scroll;
}

.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.signUp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 500px) {
  .modal {
    width: 94vw;
  }
}