.row {
  display: flex;
}

.rowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dtRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dtRowTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.dtRowTopSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rowLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rowTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.rowSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.columnStartCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dtColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dtColumnLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.columnRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.columnTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width:500px) {
  .dtRowTopSpaceBetween,
  .dtRowTop,
  .dtRowCenter,
  .dtColumnCenter,
  .dtColumnLeft {
    flex-direction: unset;
    justify-content: unset;
    align-items: unset;
  }

  .mbColumnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mbColumnRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}