.brand_nugget_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.brand_nugget {
  background-color: white;
  color: black;
  opacity: .4;
  border-radius: 5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  width: 100%;
  height: 4rem;
  border: 3px solid black;
  box-sizing: border-box;
}

.brand_nugget p {
  font-size: 15px;
  line-height: 16px;
  margin-right: 1rem;
}

.selected.brand_nugget {
  background-color: black;
  color: white;
  opacity: 1;
}

.brand_nugget_image {
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 2rem;
  margin-right: 0.75rem;
  object-fit: cover;
  background-color: white;
}