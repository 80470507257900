:root {
  --footerHeight: 200px;
  --navHeight: 80px;
  --borderRadius: 10px;
  --red: #ff005e;
  /* --red: #EA4715; */
  --pink: #FFC3DE;
  --pinkHover: #dd9dba;
  --lavender: #d9dfff;
  --buttonHeight: 54px;
  --orange: #ff8400;
  --black: #000000;
  --blue: #153eea;
  --lightGrey: #f4f4f4;
  --mediumGrey: #e9e9e9;
  --grey: #e8e8e8;
  --darkGrey: #7e7e7e;
  --purple: #7700ff;
  --neon: #ebff2f;
  --shadow: 0 6px 20px 0 rgba(0,0,0,.2);
  /* --neon: #FBFF2F; */

  --border: 2px solid black;
  --borderNeutral: 2px solid var(--grey);

  --smallPageContent: 600px;

  --mediumPageContentMin: 800px;
  --mediumPageContent: 1200px;

  --maxPageContentMin: 1000px;
  --maxPageContent: 1800px;

  /* Media queries */
  --tabletDown: 768px;
  --desktopDown: 1025px;
  --widescreenDown: 1325px;
  /* GIGANTIC variables */
  /* --white: #FFFFFF;
  --black: #000000;
  --red: #EA4715;
  --orange: #FF6B01;
  --pink: #FFC3DE;
  --green: #009C45;
  --yellow: #FFD200;
  --brown: #85543C;
  --mint: #00E09D;
  --cream: #F2E8D9;
  --darkcream: #CBC3B6;
  --gray: #949494;
  --lightgray: #E6E6E6; */
}