:root {
  --brandContainerWidth: 16rem;
  --landingDropContainerHeight: 380px;
}

.card {
  width: 320px;
  height: 500px;
  min-width: 320px;
  min-height: 500px;
  max-width: 320px;
  max-height: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}

.brandInfo {
  margin-bottom: 1rem;
}

.brandLogo {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.brandName {
  font-size: 20px;
  color: black;
}

.cardImage {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.cardHeader {
  font-size: 24px;
  line-height: 24px;
  min-height: 48px;
  margin-bottom: 1rem;
}

.startDate {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--darkGrey);
  margin-bottom: 0.5rem;
}

.cardTag {
  position: relative;
  padding: 0.5rem 1rem;
  background-color: black;
  border-radius: 2rem;
  color: white;
}

.cardTag p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productTag {
  background-color: black;
}

.collectionTag {
  background-color: var(--purple);
}

.toolTipText {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 200px;
  bottom: calc(100% + 10px);
  left: 50%;
  margin-left: -100px;
  font-size: 14px;
}

.memberToolTip:hover .toolTipText {
  visibility: visible;
}

.memberToolTip .toolTipText::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.infoSpan {
  font-family: Headline;
  margin-left: 0.25rem;
}

.info {
  width: 14px;
  height: 14px;
  margin-left: 0.25rem;
}

@media (max-width:500px) {}