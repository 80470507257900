.signUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.signUp_text {
  margin: 1rem 0 2.5rem;
}

.signUp_text h3 {
  font-size: 32px;
}

.signUp_form {
  position: relative;
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input {
  margin-bottom: 1rem;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.signUp_form p {
  position: absolute;
  top: calc(100% + .5rem);
  left: 0;
  color: var(--red);
}

.countrySelect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.countrySelect button:first-of-type {
  margin-right: 0.5rem;
}

.inactiveButton {
  opacity: .5;
}