.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: scroll;
  height: 100%;
}

.formBrand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 8rem;
  padding-left: 2rem;
}

.formBrand_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(10px);
}

.imageUpload {
  border: none;
}

.logo {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  border: var(--border);
  object-fit: cover;
  z-index: 1;
  margin-right: 1rem;
  background-color: white;
}

.brandName {
  font-size: 32px;
  margin-top: 0.75rem;
  z-index: 1;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.signUp_form {
  position: relative;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
}

.signUp_form h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input {
  margin-bottom: 1rem;
}

.signUp_form select:last-of-type {
  margin-bottom: 0;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.signUp_form h5 {
  margin: 2rem 0 1rem 0;
}

.imageUpload[type="file"] {
  display: none;
}

.filePickerButton {
  padding: 0 2rem;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.button {
  margin-bottom: 2rem;
}

.existingCode {
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: space-between;
  align-items: center;
  height: 3rem;
  border-radius: var(--borderRadius);
  background-color: var(--grey);
  margin-bottom: 1rem;
}

.emptyMessage {
  text-align: center;
  width: 100%;
  opacity: .4;
  text-transform: uppercase;
}

.codeSection {
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.codeForm {
  border-right: 1px solid white;
  font-weight: 800;
  width: 30%;
}

.descriptionForm {
  border-right: 1px solid white;
  width: 40%;
}

.couponCode {
  border-right: 1px solid white;
  font-weight: 800;
  width: 30%;
}

.couponDescription {
  border-right: 1px solid white;
  width: 40%;
}

.dateRange {}

.deactivate {
  cursor: pointer;
  background-color: black;
  color: white;
  width: 30%;
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}