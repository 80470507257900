:root {
  --brandContainerWidth: 16rem;
  --saleTileHeight: 10rem;
}

.isFirst.saleContainer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.saleContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 6px;
  width: 100%;
  min-height: var(--saleTileHeight);
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: white;
}

/* .saleContainer:hover {
  background-color: var(--lightGrey);
} */

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.info {
  width: 14px;
  height: 14px;
  margin-left: 0.25rem;
}

.memberToolTip {
  position: relative;
  display: inline-block;
}

.toolTipText {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 200px;
  bottom: calc(100% + 10px);
  left: 50%;
  margin-left: -100px;
}

.memberToolTip:hover .toolTipText {
  visibility: visible;
}

.memberToolTip .toolTipText::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.brandContainer {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: var(--brandContainerWidth);
  max-width: var(--brandContainerWidth);
  transform: translateX(-2rem);
}

.brandContainer h3 {
  font-size: 14px;
}

.brandRegion {
  text-transform: uppercase;
  margin-right: 0.5rem;
  color: var(--purple);
}

.salesImg {
  min-height: var(--saleTileHeight);
  min-width: var(--saleTileHeight);
  max-height: var(--saleTileHeight);
  max-width: var(--saleTileHeight);
  border-radius: 6px;
  object-fit: cover;
  margin-right: 1rem;
}

.logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
  margin-right: 0.75rem;
  border: 2px solid white;
}

.saleInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 0rem 0 0 0.5rem;
}

.saleDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.saleHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}

.isEditForm .saleHeader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.75rem;
}

.isEditForm .saleHeader h4 {
  line-height: 1.5rem;
}

.salePercentage {
  background-color: var(--purple);
  padding: 0.25rem 1rem;
  border-radius: 22px;
  color: white;
  font-size: 16px;
  margin-right: 1rem;
}

.salePercentage.inactive {
  background-color: var(--darkGrey);
}

.saleTags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.saleTag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: black;
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.5rem;
  height: 26px;
  margin-right: 0.5rem;
}

.saleTag p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saleTag .infoSpan {
  font-family: Headline;
  margin-left: 0.25rem;
}

.saleTag span {
  margin-left: 4px;
}

.seasonal {
  background-color: var(--blue);
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25rem;
}

.top h4 {
  font-size: 1.75rem;
  line-height: 1.5rem;
  font-family: Title;
  margin-bottom: 0.5rem;
  font-family: Title;
}

.top p {
  line-height: 18px;
}

.isAdminDashboard.saleContainer {
  background-color: white;
}

.isAdminDashboard.saleContainer:hover .dates {
  display: none;
}

.isEditForm .saleInfo {
  width: 100%;
}

.content p {
  font-size: 14px;
}

.dates {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
}

.date p:first-of-type {
  text-transform: uppercase;
  color: var(--darkGrey);
  font-size: 10px;
}

.date p {
  font-size: 12px;
  line-height: 14px;
}

.actionsContainer {
  position: relative;
  display: none;
  width: calc(100% - var(--brandContainerWidth));
}

.isAdminDashboard.saleContainer:hover .actionsContainer,
.isEditForm.saleContainer:hover .actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  width: fit-content;
  background-color: white;
  border-radius: var(--borderRadius);
  padding: 0 1rem;
  width: fit-content;
  transform: translateX(-50%);
  height: 4rem;
}

.smallSaleTile_button {
  cursor: pointer;
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 6px;
  width: 7rem;
}

.url {
  background-color: var(--blue);
}

.edit {
  background-color: var(--black);
}

.deactivate {
  background-color: var(--darkGrey);
}

.delete {
  background-color: var(--red);
}

.loading {
  position: absolute;
  right: -4rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: var(--borderRadius);
  padding: 1rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIcon {
  width: 2rem;
}

@media (max-width:500px) {
  .saleContainer {
    flex-direction: column;
    padding: 0;
  }

  .brandContainer {
    width: 100%;
    min-width: unset;
    max-width: unset;
    transform: unset;
  }

  .logo {}

  .saleInfo {
    padding: 1rem;
    flex-direction: column;
    margin: 0;
  }

  .top {
    width: 100%;
    margin-bottom: 1rem;
  }

  .dates {
    width: 100%;
    justify-content: flex-end;
  }

  .salesImg {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    padding: 1rem 1rem 0 1rem;
  }
}