.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: scroll;
  max-height: 90vh;
}

.signUp_form {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem 0;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.signUp_form h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input,
.signUp_form select {
  margin-bottom: 1rem;
}

.signUp_form input:last-of-type {
  margin-bottom: 0;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.formInput {
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.formInputInsider {
  margin-bottom: 0;
  border-bottom: 1px solid var(--grey);
  padding-bottom: 2rem;
  height: 100%;
  transition: 0.2s;
}

.formInputInsiderCollapsed {
  display: none;
}

.formBrand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 11rem;
  max-height: 11rem;
  padding-left: 2rem;
}

.formBrand_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(10px);
}

.imageUpload {
  border: none;
}

.logo {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  border: solid 4px white;
  object-fit: cover;
  z-index: 1;
  margin-right: 1rem;
  background-color: white;
}

.brandName {
  font-size: 32px;
  margin-top: 2rem;
  z-index: 1;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.departmentContainer {
  margin: 1rem 0;
}

.department {
  margin: 0 1rem 0 0;
}

.department input {
  margin: 0 0.5rem 0 0 ;
  height: 1rem;
}

.formLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 1.5rem;
}

.formLabel p {
 margin-left: 2rem;
 font-size: 16px;
 color: var(--darkGrey);
}

.formLabelInsider {
  cursor: pointer;
}

.triangle {
  width: 1rem;
  margin-left: 1rem;
}

.insiderCollapsed {
  transform: rotateX(180deg);
}

.imageUpload[type="file"] {
  display: none;
}

.filePickerButton {
  padding: 0 2rem;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.uploadContainer:first-of-type {
  margin-right: 1rem;
}

.uploadContainer p {
  font-size: 10px;
  margin-top: 10px;
  max-width: 100px;
  height: 26px;
  overflow: hidden;
}

.button {
  margin-bottom: 2rem;
}

.uploadButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.pic_preview {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  z-index: 1;
  background-color: var(--grey);
  margin-bottom: 1rem;
}

.cover_preview {
  width: 200px;
}