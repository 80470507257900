.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 2rem;
}

.signUp_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.formBrand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.imageUpload {
  border: none;
}

.logo {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  border: 3px solid white;
  object-fit: cover;
  z-index: 1;
  margin-right: 1rem;
}

.brandName {
  font-size: 32px;
  margin-top: 0.75rem;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.signUp_form {
  position: relative;
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.signUp_form h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input {
  margin-bottom: 1rem;
}

.signUp_form input:last-of-type {
  margin-bottom: 0;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.imageUpload[type="file"] {
  display: none;
}

.filePickerButton {
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer button {
  margin-right: 1rem;
}

@media (max-width:500px) {
  .form {
    padding: 2rem 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
}