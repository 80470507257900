.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: scroll;
}

.formBrand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 8rem;
  padding-left: 2rem;
}

.formBrand_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(10px);
}

.editForm {
  width: 100%;
}

.imageUpload {
  border: none;
}

.logo {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  border: var(--border);
  object-fit: cover;
  z-index: 1;
  margin-right: 1rem;
  background-color: white;
}

.brandName {
  font-size: 32px;
  margin-top: 0.75rem;
  z-index: 1;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.signUp_form {
  position: relative;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.signUp_form h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input {
  margin-bottom: 1rem;
}

.signUp_form input:last-of-type {
  margin-bottom: 0;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.imageUpload[type="file"] {
  display: none;
}

.filePickerButton {
  padding: 0 2rem;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.button {
  margin-bottom: 2rem;
}

.contentContainer {
  width: 96%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contentContainer label {
  text-transform: uppercase;
  color: var(--darkGrey);
  font-size: 12px;
}

.salesContainer {
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyContainer {
  background-color: var(--grey);
}

.smallSaleTile {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: var(--borderRadius);
}

.smallSaleTile_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}

.smallSaleTile_details h4 {
  font-size: 18px;
}

.smallSaleTile_details_dates p {
  font-size: 12px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.smallSaleTile_button {
  cursor: pointer;
  width: 5rem;
  background-color: black;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallSaleTile_button:last-of-type {
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  background-color: red;
}

.noSales {
  width: 100%;
  text-transform: uppercase;
  color: var(--darkGrey);
  text-align: center;
  padding: 1rem 0;
}