@import './variables.css';

@font-face {
  font-family: Headline;
  /* src: url("./assets/fonts/DrukTextWide-Heavy.otf") format("opentype"); */
  /* src: url("./assets/fonts/PPRightGrotesk-SpatialBlack.otf") format("opentype"); */
  src: url("./assets/fonts/PPRightGrotesk-WideBlack.otf") format("opentype");

  /* src: url("./assets/fonts/Garnett-Black.ttf") format("truetype"); */
  /* src: url("./assets/fonts/Snappz.otf") format("opentype"); */
}

@font-face {
  font-family: Title;
  src: url("./assets/fonts/PPRightGrotesk-CompactBlack.otf") format("opentype");
  /* src: url("./assets/fonts/Garnett-Black.ttf") format("truetype"); */
}

@font-face {
  font-family: Text;
  src: url("./assets/fonts/Garnett-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Text", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: Headline;
  font-weight: 300;
}

h4 {
  font-size: 1.5rem;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  height: var(--buttonHeight);
  background-color: var(--grey);
  border-radius: var(--borderRadius);
  padding: 0 1rem;
  border: 2px solid transparent;
  height: 3rem;
}

input:focus {
  outline: none;
  border-color: black;
}

input::placeholder {
  font-family: Text;
}

button {
  outline: none;
  border: none;
}

/* :global(.row) {
  display: flex;
}

:global(.column) {
  display: flex;
  flex-direction: column;
}

:global(.columnStart) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

:global(.dtColumnCenter) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:global(.dtRowCenter) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */