:root {
  --brandContainerWidth: 16rem;
  --couponTileHeight: 5rem;
}

.isFirst.dropTile {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.dropTile {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  min-height: var(--couponTileHeight);
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 6px;
}

.container:hover {
  background-color: var(--lightGrey);
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.main h4 {
  font-family: Title;
}

.main p {
  line-height: 18px;
}

.tag {
  background-color: var(--purple);
  padding: 0.25rem 1rem;
  border-radius: 22px;
  color: white;
  font-size: 16px;
  margin-right: 0.75rem;
}

.productTag {
  background-color: var(--neon);
  color: black;
  border: 2px solid black;
}

.actionsContainer {
  display: none;
  width: calc(100% - var(--brandContainerWidth));
}

.actionsContainer a {
  color: var(--blue);
  width: 4rem;
}

.container:hover .actionsContainer {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  width: fit-content;
}

.carouselContainer {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
}

.carousel img {
  height: 600px;
  width: auto;
  object-fit: cover;
  border-radius: var(--borderRadius);
  margin-right: 1rem;
}

.carousel img:last-of-type {
  margin-right: 0;
}

.carouselPadding {
  min-width: 1rem;
  height: 10rem;
  background-color: transparent;
}

.carousel .isOnlyImage {
  width: 100%;
}

@media (max-width:500px) {
  .container {
    flex-direction: column;
  }

  .carousel img {
    height: auto;
    width: calc(100% - 1rem);
  }
}