:root {
  --tileWidth: 18rem;
  --brandTileHeight: 18rem;
  --coverPicHeight: 6rem;
  --borderThickness: 2px;
  --outlineThickness: 4px;
  --followButtonHeight: 2.5rem;
}

.tile_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  width: var(--tileWidth);
  height: var(--brandTileHeight);
  border: var(--border);
}

.tile_container h4 {
  font-size: 1.5rem;
  line-height: 1.55rem;
  text-align: center;
}

.tile_container.disableFollowButton {
  height: calc(var(--brandTileHeight) - 3rem);
}

.tile_container.disableFollowButton:hover .brandDescription {
  display: inherit;
}

.tile_container.disableFollowButton .followButton {
  display: none;
}

.tile_container.disableFollowButton:hover {
  border: solid var(--borderThickness) var(--purple);
}

.tile_container.disableFollowButton.staticTile:hover {
  border: solid var(--borderThickness) black;
}

.tile_container.disableFollowButton:hover::after {
  content: "";
  position: absolute;
  top: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  right: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  bottom: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  left: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  border: solid calc(var(--borderThickness) + var(--outlineThickness)) var(--purple);
}

.tile_container.disableFollowButton.staticTile:hover::after {
  content: "";
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  border: unset;
}

.followButton {
  display: flex;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  height: var(--followButtonHeight);
  width: calc(100% - 2rem);
  background-color: white;
  color: black;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--border);
}

.brandCover {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--white);
  width: 100%;
  height: var(--coverPicHeight);
  object-fit: cover;
}

.tileDetails {
  width: 100%;
  padding: 0 1rem;
  margin-top: calc(var(--coverPicHeight) - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
}

.tileDetails_main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.tileDetails_main h4 {
  margin-top: 1.75rem;
  line-height: 1.5rem;
  text-align: left;
}

.tileDetails p {
  font-size: 14px;
  height: 40px;
  overflow: hidden;
}

.brandLogo {
  width: 4rem;
  height: 4rem;
  border: var(--borderThickness) solid white;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  background-color: white;
  -webkit-border-radius: 50%;
}

.isSelected {
  border: var(--borderThickness) solid var(--purple);
}

.isSelected::after {
  content: "";
  position: absolute;
  top: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  right: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  bottom: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  left: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
  border: solid calc(var(--borderThickness) + var(--outlineThickness)) var(--purple);
  border-radius: calc(var(--borderRadius) + var(--outlineThickness));
}

.staticTile {
  cursor: default;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100px;
  overflow: hidden;
  border-radius: var(--borderRadius);
}

@media (max-width:500px) {
  .tile_container {
    height: var(--brandTileHeight);
  }

  .disableFollowButton.tile_container {
    height: calc(var(--brandTileHeight) - 3.5rem);
  }

  .tile_container .followButton {
    display: flex;
  }

  .tileDetails {
    height: calc(var(--brandTileHeight) - var(--coverPicHeight) - 0.25rem);
    padding-bottom: 1rem;
  }

  .disableFollowButton .tileDetails {
    padding-bottom: 4rem;
  }

  .followButton {
    position: relative;
    bottom: unset;
    left: unset;
    background-color: black;
    width: 100%;
    color: white;
  }

  .disableFollowButton.tile_container .followButton {
    display: none;
  }

  .followButton:hover {
    background-color: black;
    color: white;
  }

  .tile_container:hover .brandDescription {
    display: flex;
  }

  .tile_container .followButton {
    display: flex;
  }

  .tile_container.disableFollowButton:hover {
    border: solid var(--borderThickness) black;
  }

  .tile_container.isSelected {
    border: solid var(--borderThickness) var(--purple) !important;
  }

  .tile_container.isSelected::after {
    content: "";
    position: absolute;
    top: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
    right: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
    bottom: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
    left: calc(-1*(var(--borderThickness) + var(--outlineThickness)));
    border: solid calc(var(--borderThickness) + var(--outlineThickness)) var(--purple);
    border-radius: calc(var(--borderRadius) + var(--outlineThickness));
  }
}