:root {
  --picHeight: 100px;
  --contentPadding: 1rem;
  --leftWidth: 360px;
}

.page {
  background-color: var(--grey);
}

.content {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  min-height: calc(100vh - var(--navHeight));
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  min-width: 1000px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.dashboard_left {
  width: var(--leftWidth);
  min-width: var(--leftWidth);
  position: sticky;
  top: 1rem;
}

.dashboard_left>section {
  margin-bottom: 1rem;
}

.dashboard_right {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  width: calc(100% - var(--leftWidth) - 1rem);
  min-width: 624px;
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.container {
  background-color: white;
}

.widget {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: var(--borderRadius);
}

.widget_title {
  text-transform: uppercase;
  color: var(--darkGrey);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-bottom: 1rem;
  background-color: white;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.widget_title p {
  font-size: 12px;
}

.brand_general {
  /* height: 530px; */
}

.top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.brand_similar {
  height: fit-content;
}

.brand_general_pics {
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: 3rem;
}

.cover {
  width: 100%;
  height: 100%;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  object-fit: cover;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.brandLogo {
  position: absolute;
  left: 1.5rem;
  bottom: -1.5rem;
  width: var(--picHeight);
  height: var(--picHeight);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandLogo .loading {
  margin-bottom: 0;
}

.widget_section_left_justify {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2rem;
  margin-bottom: 1.5rem;
}

.widget_section_center_justify {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  height: fit-content;
}

.brand_similar_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 1rem;
  height: fit-content;
}

.quarterWidth {
  grid-column: 1 / 4;
}

.name p {
  font-size: 14px;
}

.brandDescription {
  margin-top: 0.5rem;
}

.insider {
  margin-top: 2rem;
}

.insider a,
.insider p {
  font-size: 14px;
}

.insiderRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.insiderRow p:first-of-type {
  margin-right: 0.5rem;
  width: 12rem;
  font-size: 12px;
  color: var(--darkGrey);
  margin-top: 0.125rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row p:first-of-type {
  width: unset;
}

.row svg {
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 2rem;
}

.followButton {
  margin: 0 0.5rem 0 1.5rem;
}

.brand_wrapper {
  display: flex;
  flex-direction: column;
}

.brand_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--contentPadding);
}

.brand_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  align-items: flex-start;
  padding: 4rem;
}

.fullWidth {
  grid-column: span 7;
}

.likeButton {
  cursor: pointer;
  height: var(--buttonHeight);
  width: var(--buttonHeight);
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.likeButton img {
  width: 28px;
  padding-top: 2px;
}

.likeButton.isLiked img {
  filter: invert(29%) sepia(87%) saturate(7034%) hue-rotate(337deg) brightness(96%) contrast(91%);
}

.sales_container {
  width: 100%;
  /* padding: 0 1rem; */
}

.coupon_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}

.emptySalesContainer {
  background-color: white;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  width: 100%;
  padding: 1rem;
}

.emptySalesContainer.emptyCouponsContainer.leftCoupon {
  padding-right: 0;
  padding-left: 1rem;
}

.emptySalesContainer.emptyCouponsContainer {
  padding-left: 0;
}

.emptySales {
  width: 100%;
  background-color: rgb(210, 210, 210);
  padding: 1rem;
  border-radius: calc(var(--borderRadius) - 4px);
  text-align: center;
  opacity: .4;
  text-transform: uppercase;
  font-size: 14px;
}

.loading {
  opacity: .7;
  width: 2rem;
  margin-bottom: 2rem;
}


@media (max-width:500px) {
  .content {
    width: 100vw;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    max-width: unset;
    min-width: unset;
  }

  .dashboard_left {
    width: 100%;
    margin-bottom: 0;
    position: relative;
    top: unset;
  }

  .dashboard_right {
    min-width: unset;
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0;
  }

  .widget_section_left_justify {
    padding: 0 1rem;
  }
}