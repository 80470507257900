.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4rem 4rem 10rem 4rem;
  max-width: var(--smallPageContent);
  min-width: var(--smallPageContent);
  height: 100%;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.intro h2 {
  font-size: 3.5rem;
  line-height: 4rem;
}

.signUp_form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

@media (max-width:500px) {
  .content {
    width: 100%;
    padding: 2rem;
    max-width: unset;
    min-width: unset;
  }

  .signUp_form_container {
    margin-top: 2rem;
  }
}