.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4rem 4rem 10rem 4rem;
  width: 100vw;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
}

.intro h2 {
  font-size: 3.5rem;
  line-height: 4rem;
}

.brand_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  align-items: center;
  padding: 4rem;
}

.allBrands_container {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.button {
  opacity: 0.5;
}

.button:first-of-type {
  margin-right: 1rem;
}

.activeButton {
  opacity: 1;
}

.inputContainer {
  width: 16rem;
}

.inputContainer label {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--darkGrey);
}

.filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.filters>div:first-of-type {
  margin-right: 1rem;
}

@media (max-width: 1200px) {
  .content {
    padding: 1rem 1rem 3rem 1rem;
  }
}