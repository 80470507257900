:root {
  --brandContainerWidth: 16rem;
  --couponTileHeight: 5rem;
}

.userTile {
  width: 100%;
  background-color: white;
  border-radius: var(--borderRadius);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}