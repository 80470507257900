:root {
  --contentPadding: 5rem;
  --contentWidth: 1000px;
  --userWidth: 400px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4rem;
  max-width: var(--smallPageContent);
  min-width: var(--smallPageContent);
}

.leftSection {
  width: 60%;
}

.rightSection {
  width: 40%;
}

.intro {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
}

.intro h2 {
  font-size: 3.5rem;
  line-height: 4rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 3rem;
}

.section h4 {
  margin-bottom: 1rem;
}

.subscriptionOptions {
  display: grid;
  column-gap: 2rem;
  width: 100%;
  height: 14rem;
  grid-template-columns: repeat(2, 1fr);
}

.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.profile_info_inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;
}

.method_container {
  width: 50%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}

.inputContainer label {
  font-size: 14px;
  text-transform: uppercase;
  opacity: .6;
  margin-bottom: 6px;
}

.inputContainer input {
  width: 100%;
}

.inputContainer:first-of-type {
  margin-right: 1rem;
}

.errorMsg {
  color: red;
  margin-left: 1rem;
}

.option {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius);
  color: white;
  padding: 1rem;
}

.option h3 {
  font-size: 20px;
}

.optionOne {
  background-color: blue;
}

.optionTwo {
  background-color: black;
}

.reset {
  background-color: rgb(115, 0, 255);
}

.user {
  position: fixed;
  left: calc((100vw - var(--contentWidth)) / 2);
  top: calc(var(--navHeight) + 20vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: var(--userWidth);
}

.user button {
  margin-bottom: 1rem;
}

.brands {
  width: calc(100% - var(--userWidth));
  margin-left: var(--userWidth);
}

.details h3 {
  font-size: 40px;
}

.brand_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planTile {
  width: 100%;
  padding: 1rem 2rem;
  border: var(--border);
  border-radius: var(--borderRadius);
}

.billingLoading {
  width: 1.5rem;
  margin-left: 1rem;
}

@media (max-width:500px) {
  .content {
    padding: 2rem;
    width: 100%;
  }

  .intro h2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .profile_info_inputs {
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
  }

  .method_container {
    margin-bottom: 1.5rem;
  }

  .profile_info_name {
    flex-direction: column;
    align-items: flex-start;
  }

  .inputContainer:first-of-type {
    margin-bottom: 1.5rem;
  }

  .subscriptionOptions {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 1rem;
  }
}