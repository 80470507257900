footer {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--footerHeight);
  width: 100vw;
  color: white;
  padding: 0 5vw;
}

footer p {}

.beta {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-transform: uppercase;
  font-family: Headline;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.content h3 {
  font-size: 3rem;
  letter-spacing: 3.5px;
}

.logo_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 5rem;
}

.snappi_logo {
  height: 54px;
  margin-top: -10px;
  margin-right: 1rem;
}

.logo {
  text-decoration: none;
  font-size: 48px;
  letter-spacing: 3.5px;
}

.socialContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.socialContainer img {
  width: 1.5rem;
  margin-right: 0.75rem;
}

@media (max-width:500px) {
  .content {
    justify-content: center;
    align-items: center;
  }

  .logo_container {
    margin-bottom: 0;
  }
}