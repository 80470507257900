:root {
  --contentPadding: 5rem;
}

.page {
  position: relative;
  width: 100vw;
  min-width: 1264px;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  min-height: calc(100vh - var(--navHeight));
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  background-color: var(--grey);
}

.dashboard_left {
  width: calc(25% - 0.5rem);
  /* max-width: 350px; */
  min-width: 300px;
  position: sticky;
  bottom: 1rem;
}

.dashboard_left>section {
  margin-bottom: 1rem;
}

.dashboard_right {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  width: calc(75% - 0.5rem);
  min-width: 700px;
  margin-bottom: 2rem;
}

.loading {
  width: 4rem;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --picHeight: 100px;
  --contentPadding: 1rem;
}

.widget {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: var(--borderRadius);
}

.widget_title {
  text-transform: uppercase;
  color: var(--darkGrey);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-bottom: 1rem;
}

.container {
  background-color: white;
}

.coupon_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  padding: 0 1rem;
}

.widget_title {
  background-color: white;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.widget_title p {
  font-size: 12px;
}

.user {
  background-color: white;
}

.following {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
}

.brandNuggetWrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.brandNuggetWrapper:last-of-type {
  margin-bottom: 0;
}

.brand_similar {
  height: fit-content;
}

.widget_section_left_justify {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2rem;
  margin-bottom: 1.5rem;
}

.widget_section_center_justify {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  height: fit-content;
}

.brand_similar_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 1rem;
  height: fit-content;
}

.sales_analytics {
  grid-column: 1 / 4;
}

.name {
  height: 100%;
  justify-content: center;
}

.name h1 {
  margin: 1rem 0;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.name p {
  font-size: 14px;
  color: var(--darkGrey);
  text-transform: uppercase;
  width: 7rem;
}

.name span {
  font-size: 14px;
  color: black;
  text-transform: uppercase;
}

.user_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.details {
  font-size: 14px;
}

.details h3 {
  font-size: 40px;
}

.brand_wrapper {
  display: flex;
  flex-direction: column;
}

.brand_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--contentPadding);
}

.brand_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  align-items: flex-start;
  padding: 4rem;
}

.fullWidth {
  grid-column: span 7;
}

.single {
  grid-column: span 7;
}

.half {
  grid-column: span 3;
}

.third {
  grid-column: span 2;
}

.fourth {
  grid-column: span 1;
}

.coupon_copy {
  cursor: pointer;
  width: calc(100% - 2rem);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0.5rem 1rem 1rem;
}

.coupon_copy:hover {
  background-color: var(--grey);
}

.activeCouponOther {
  grid-column: 4/7;
}

.likeButton {
  cursor: pointer;
  height: var(--buttonHeight);
  width: var(--buttonHeight);
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.likeButton img {
  width: 28px;
  padding-top: 2px;
}

.likeButton.isLiked img {
  filter: invert(29%) sepia(87%) saturate(7034%) hue-rotate(337deg) brightness(96%) contrast(91%);
}

.sales_container {
  width: 100%;
}

.emptySales {
  width: 100%;
  background-color: rgb(210, 210, 210);
  padding: 1rem;
  border-radius: calc(var(--borderRadius) - 4px);
  text-align: center;
  opacity: .4;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 14px;
}

.loading {
  opacity: .7;
  width: 2rem;
  margin-bottom: 2rem;
}


@media (max-width:500px) {
  .page {
    width: 100vw;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    min-width: unset;
  }

  .dashboard_left {
    width: 100%;
    margin-bottom: 0;
  }

  .dashboard_right {
    min-width: unset;
    width: 100%;
    margin-bottom: 2rem;
  }

  .widget_section_left_justify {
    padding: 0 1rem;
  }

  .coupon_container {
    grid-template-columns: 1fr;
  }
}