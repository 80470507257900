.dropdown {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: var(--borderNeutral);
  border-radius: var(--borderRadius);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  background-color: var(--grey);
}

.isActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown.isActive {
  border: var(--border);
}

.downarrow {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: -2px;
  width: calc(100% + 4px);
  z-index: 2;
}

.optionsContainer div {
  border: var(--border);
  margin-bottom: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
}

.optionsContainer div:hover {
  background-color: black;
  color: white;
}

.dropdownCancel {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}