:root {
  --contentPadding: 3rem;
  --sidebarWidth: 12rem;
}

.page {
  background-color: var(--grey);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100vw;
}

.container {
  padding: 2rem 2vw 3rem;
  width: calc(100vw - var(--sidebarWidth));
}

.sidebar {
  width: var(--sidebarWidth);
  height: 100vh;
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar p {
  margin: 1rem;
  font-size: 14px;
  color: black;
}

p.adminTitle {
  color: var(--darkGrey);
}

.tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.5rem;
}

.tabs h4 {
  font-size: 16px;
  color: white;
}

.tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0 1rem;
  width: 90%;
  height: 4rem;
  border-radius: 6px;
}

.activeTab.tab {
  background-color: black;
}

.activeTab.tab p {
  background-color: black;
  color: white;
}

.toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
  height: 3rem;
}

.toggleContainer h4 {
  font-size: 16px;
  color: white;
}

.toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkGrey);
  padding: 0 1rem;
  height: 100%;
  width: 50%;
}

.activeToggle.toggle {
  background-color: black;
}

.toggle:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.toggle:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}


.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.containerHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem 0;
}

@media screen and (max-width: 1025px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}