@import '../../variables.css';

.nav {
  width: 100vw;
  height: var(--navHeight);
  background-color: white;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); */
}

.signedIn {}

.nav_main {
  width: 33.333%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.signedIn .nav_main {
  width: 40%;
  min-width: 410px;
}

.nav_allBrands_link {
  min-width: 75px;
}

.nav_allBrands_link h4 {
  font-size: 16px;
  font-family: Title;
  text-transform: uppercase;
  white-space: nowrap;
}

.nav_logo_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 2rem;
}

.nav_snappi_logo {
  height: 36px;
  margin-top: -10px;
  margin-right: 1rem;
}

.nav_logo {
  color: black;
  text-decoration: none;
  font-size: 28px;
  letter-spacing: 2px;
}

.search {
  position: relative;
  width: 35%;
  height: 3rem;
  z-index: 5;
}

.signedIn .search {
  width: 45%;
}

.nav_buttons {
  width: 30%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

.signedIn .nav_buttons {
  width: 10%;
}

.dropdown_button {
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dropdown_button h4 {
  font-size: 16px;
  font-family: Title;
  text-transform: uppercase;
}

.downTriangle {
  width: 1rem;
  margin-left: 1rem;
}

.user {
  width: 2rem;
}

.dropdown {
  position: absolute;
  top: calc(var(--navHeight) - .5rem);
  right: 2rem;
  height: fit-content;
  width: 220px;
  border-radius: var(--borderRadius);
  z-index: 6;
  background-color: white;
  box-sizing: content-box;
  box-shadow: var(--shadow);
}

.dropdown ul {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.dropdown li {
  cursor: pointer;
  padding: 1.25rem 2rem;
  width: 100%;
  font-family: Title;
  font-size: 20px;
  text-transform: uppercase;
}

.dropdown a {
  height: fit-content;
  width: 100%;
  border-bottom: var(--border);
}

.dropdown li:hover {
  color: white;
  background-color: black;
}

.dropdown a:last-of-type {
  border-bottom: none;
}

.dropDownItemFirst {
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.dropDownItemLast {
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.dropdown_cancel {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 5;
}

.buttonContainer {
  padding: 0 2rem 1rem 2rem;
  background-color: white;
  color: black;
}

.buttonContainer:hover {
  background-color: black;
  color: white;
}

.hamburger,
.hamburgerMenu {
  display: none;
}

@media (max-width:500px) {
  .nav {
    position: relative;
    padding: 0 20px;
  }

  .nav p {
    font-size: 15px;
  }

  .nav_main {
    width: unset;
    min-width: unset;
    margin-right: 0;
  }

  .signedIn .nav_main {
    width: unset;
    min-width: unset;
  }

  .nav_logo_container {
    margin-right: 0;
    margin-left: -14px;
  }

  .nav_allBrands_link {
    display: none;
  }

  .nav_logo {
    font-size: 26px;
    letter-spacing: 0.5px;
  }

  .nav_snappi_logo {
    height: 38px;
    margin-top: -7.5px;
    margin-right: .75rem;
  }

  .search {
    display: none;
  }

  .nav_buttons {
    width: unset;
  }

  .signedIn .nav_buttons {
    width: unset;
  }

  .downTriangle {
    display: none;
  }

  .dropdown {
    right: 1rem;
  }

  .user {
    width: 1.75rem;
  }

  .hamburger,
  .hamburgerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hamburger_line {
    width: 1.75rem;
    height: 3px;
    background-color: black;
    margin: 2px;
  }

  .hamburgerMenu {
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    width: 100vw;
    background-color: white;
    z-index: 7;
    padding: 0.5rem 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: var(--border);
  }

  .hamburgerMenu a:first-child {
    margin-bottom: 1rem;
    font-family: Headline;
    font-size: 20px;
  }
}