.searchWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 5;
}

.searchWrapper>div {
  width: 100%;
}

.searchWrapper ol {
  list-style-type: none;
}

.searchWrapper button {
  display: none;
}

.searchWrapper [type="search"]::-webkit-search-cancel-button {
  display: none;
}

.searchWrapper ul {
  padding: 0;
  width: 100%;
  margin-top: 0;
  z-index: 7;
}

.searchWrapper input {
  width: 100%;
}

.searchWrapper input:focus {
  outline: none;
  border-color: inherit !important;
}

.searchWrapper.isSearchActive input {
  border-color: black;
}

.searchWrapper.isSearchActive .countrySelect {
  border-color: black;
}

.searchWrapper.isSearchActive.hasHits input {
  border-bottom-right-radius: 0;
}

.searchWrapper.isSearchActive.hasHits .countrySelect {
  border-bottom-left-radius: 0;
}

.searchWrapper.isSearchActive .countryDropdown div {
  border-color: black;
}

.searchWrapper.isSearchActive .countryDropdown p {
  color: black;
}

.searchWrapper.isSearchActive .countryDropdown div:hover p {
  color: white;
}

.searchWrapper.isSearchActive .downarrow {
  opacity: 1;
}

.searchWrapper.isSearchActive .countryCode {
  color: black;
}

.search_input_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search_input_wrapper input {
  border-radius: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.hasUserRegion .search_input_wrapper input {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.searchWrapper.hasUserRegion.isSearchActive.hasHits input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.countrySelect p {
  font-family: Title;
  font-size: 16px;
}

.countrySelect {
  cursor: pointer;
  position: relative;
  width: 5rem;
  border: 2px solid var(--grey);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  box-sizing: border-box;
  margin-right: -2px;
}

.countryActive {
  border-bottom-left-radius: 0;
}

.countryCode {
  text-transform: uppercase;
  color: var(--darkGrey);
}

.countryDropdown {
  position: absolute;
  top: 100%;
  left: -2px;
  width: calc(100% + 4px);
  background-color: white;
  z-index: 2;
}

.countryDropdown div {
  border: solid 2px var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: -2px;
}

.countryDropdown div:hover {
  border-color: black;
}

.countryDropdown div:first-of-type {
  margin-top: 0;
}


.countryDropdown p {
  color: var(--darkGrey);
}

.countryDropdown div:hover {
  background-color: black;
}

.countryDropdown div:hover p,
.countryDropdown div:hover p {
  color: white;
}

.downarrow {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  opacity: .5;
}

.searchHit {
  cursor: pointer;
  background-color: white;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: var(--border);
  padding-left: 1rem;
  margin-top: -2px;
}

.searchHit:hover {
  background-color: black;
  color: white;
}

.searchHit img {
  min-width: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  border-radius: 3rem;
  object-fit: cover;
  background-color: white;
}

.hitText {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 3rem);
}

.hitText h4 {
  font-size: 1.25rem;
}

.hitText p {
  max-height: 36px;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
}

.root {
  width: 100%;
}

.list {
  padding-left: 0;
}

.cancel {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.dropdownCancel {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

@media (max-width:500px) {}