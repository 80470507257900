@import '../../variables.css';

.page {
  position: relative;
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - var(--navHeight));
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: var(--footerHeight);
  width: 100%;
  min-width: var(--maxPageContentMin);
  max-width: var(--maxPageContent);
}

.noFooter {
  padding-bottom: 0;
}

.preventScroll {
  overflow: hidden;
}

@media (max-width:500px) {
  .content {
    max-width: 100%;
    min-width: 100%;
  }
}