.loading {
  width: 30px;
  margin-left: 1rem;
  margin-right: -1rem;
}

.followingText,
.followingTextBrand {
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
}

.followingText {
  width: 100px;
  margin-right: 10px;
}

.followButton {
  border: var(--border);
}

.followButton.bgWhite {
  background-color: white;
}

.followButton.bgBlack {
  background-color: black;
}

.followButton.bgNeon {
  background-color: var(--neon);
}

.followButton.bgPurple {
  background-color: var(--purple);
}

.followButton.textBlack {
  color: black;
}

.followButton.textWhite {
  color: white;
}