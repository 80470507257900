.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: scroll;
}

.formBrand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 8rem;
  padding-left: 2rem;
}

.formBrand_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(10px);
}

.imageUpload {
  border: none;
}

.logo {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  border: var(--border);
  object-fit: cover;
  z-index: 1;
  margin-right: 1rem;
  background-color: white;
}

.brandName {
  font-size: 32px;
  margin-top: 0.75rem;
  z-index: 1;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.signUp_form {
  position: relative;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
}

.signUp_form h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.signUp_form input {
  width: 100%;
}

.signUp_form input, .input {
  margin-bottom: 1rem;
}

.signUp_form .formText {
  margin-bottom: 1rem;
}

/* .signUp_form input:last-of-type {
  margin-bottom: 0;
} */

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.imageUpload[type="file"] {
  display: none;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.uploadContainer p {
  font-size: 10px;
  margin-top: 10px;
  max-width: 100px;
  height: 26px;
  overflow: hidden;
}

.previewGrid {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.pic_preview {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 10px;
  object-fit: cover;
  z-index: 1;
  background-color: var(--grey);
  margin-bottom: 1rem;
}

.filePickerButton {
  padding: 0 2rem;
}

.dropDate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-bottom: 1rem;
}

.dropDate div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 6rem;
  color: black;
  background-color: var(--grey);
}

div.activeStartDate {
  background-color: black;
  color: white;
}

.dropDate div:first-of-type {
  border-radius: 6px 0 0 6px;
}

.dropDate div:last-of-type {
  border-radius: 0 6px 6px 0;
}