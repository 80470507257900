.bar {
  cursor: pointer;
  background-color: white;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.bar:hover {
  /* background-color: rgb(213, 213, 213); */
}

.company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--grey);
  height: 72px;
}

.company h4 {
  text-align: center;
  cursor: default;
  font-size: 18px;
}

.company h4:first-of-type {
  text-transform: uppercase;
  color: var(--purple);
  margin-right: 1rem;
}

.buttons {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40%;
  width: 100%;
}

.buttons div {
  width: 33.333%;
  height: 100%;
  border-left: 1px solid var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  text-align: center;
}

.buttons div:hover {
  background-color: black;
  color: white;
}

.logo {
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  object-fit: cover;
  z-index: 1;
  margin-right: 2rem;
}