:root {
  --brandContainerWidth: 16rem;
  --couponTileHeight: 5rem;
}

.container {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 6px;
  width: 100%;
  min-height: var(--couponTileHeight);
  margin-bottom: 1rem;
  padding: 1rem;
  /* background-color: var(--lightGrey); */
}

/* .container:hover {
  background-color: var(--lightGrey);
} */

.isEditForm.container:hover {
  justify-content: flex-end;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.brandContainer {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: var(--brandContainerWidth);
  max-width: var(--brandContainerWidth);
  transform: translateX(-2rem);
}

.isUserDashboard .brandContainer {
  transform: unset;
}

.brandContainer h3 {
  font-size: 14px;
}

.brandCountry {
  text-transform: uppercase;
  margin-right: 0.5rem;
  color: var(--purple);
}

.logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
  margin-right: 0.75rem;
  border: 2px solid white;
}

.saleInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 0rem 0 0 0.5rem;
}

.isBrandDashboard .saleInfo {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.isBrandDashboard .top {
  justify-content: flex-end;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}

.couponPercent {
  background-color: var(--purple);
  padding: 0.25rem 1rem;
  border-radius: 22px;
  color: white;
  font-size: 16px;
  margin-right: 1rem;
}

.couponPercent.inactive {
  background-color: var(--darkGrey);
}

.logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
  margin-right: 0.75rem;
  border: 2px solid white;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25rem;
}

.top h4 {
  font-size: 1.75rem;
  line-height: 1.5rem;
  font-family: Title;
  margin-bottom: 0.5rem;
}

.top p {
  line-height: 18px;
}

.isAdminDashboard.container {
  background-color: white;
}

.isAdminDashboard.container:hover .dates {
  display: none;
}

/* .isAdminDashboard .saleInfo {
  width: calc(100% - var(--brandContainerWidth));
} */

.isEditForm .saleInfo {
  width: 100%;
}

.content p {
  font-size: 14px;
}

.dates {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
}

.date p:first-of-type {
  text-transform: uppercase;
  color: var(--darkGrey);
  font-size: 10px;
}

.date p {
  font-size: 12px;
  line-height: 14px;
}

.actionsContainer {
  display: none;
  width: calc(100% - var(--brandContainerWidth));
}

.actionsContainer a {
  color: var(--blue);
  width: 4rem;
}

.container:hover .actionsContainer {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  width: fit-content;
}

.smallSaleTile_button {
  cursor: pointer;
  height: 3rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 6px;
}

.smallSaleTile_button:first-of-type {
  background-color: var(--darkGrey);
  margin-left: 2rem;
}

.smallSaleTile_button:last-of-type {
  background-color: var(--red);
  margin-left: 1rem;
}

@media (max-width:500px) {
  .container {
    flex-direction: column;
    padding: 0;
  }

  .brandContainer {
    width: 100%;
    min-width: unset;
    max-width: unset;
    transform: unset;
  }

  .logo {
  }

  .saleInfo {
    padding: 1rem;
    flex-direction: column;
    margin: 0;
  }

  .top {
    width: 100%;
    margin-bottom: 1rem;
  }

  .dates {
    width: 100%;
  }

  .salesImg {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
  }
}