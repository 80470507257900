.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 4rem;
  padding-bottom: 10rem;
  width: 60vw;
  min-width: var(--mediumPageContentMin);
  max-width: var(--mediumPageContent);
  height: 100%;
}

.container h2 {
  font-size: 4rem;
  line-height: 4.5rem;
}

.header {
  margin-bottom: 2rem;
}

.header p {
  color: var(--darkGrey);
}

.backArrow {
  cursor: pointer;
  position: absolute;
  left: -8rem;
  top: 10.5rem;
  width: 3rem;
  height: 3rem;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 2rem;
  height: 2rem;
  transform: rotateY(180deg);
}

.brand_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  align-items: center;
  padding: 4rem;
}

.signUp_form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signUp_plan {
  margin-bottom: 3rem;
}

.plan_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
}

.plan_container h3 {
  line-height: 50px;
  margin-bottom: 0.5rem;
}

.plan_container p:first-of-type {
  margin-bottom: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 3rem;
  width: 100%;
}

.errorMsg {
  position: absolute;
  top: calc(100% + .5rem);
  left: 0;
  color: var(--red);
}

.phone_input_container {
  width: 100%;
  margin-bottom: 2rem;
}

.phone_input {
  width: 100%;
  background-color: var(--grey);
  height: var(--buttonHeight);
  border-radius: var(--borderRadius);
}

.stripeContainer h4 {
  margin-bottom: 1rem;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionContainer {
  cursor: pointer;
  width: 50%;
  height: 400px;
  background-color: var(--darkGrey);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem;
  color: white;
}

.optionContainer:first-of-type {
  margin-right: 1rem;
}

.saleOption.isSelected {
  background-color: var(--neon);
  color: black;
}

.dropOption.isSelected {
  background-color: var(--purple);
  color: white;
}

.error {
  color: var(--red);
  margin-top: 1rem;
}

@media (max-width:500px) {
  .content {
    width: 100%;
    padding: 1rem 2rem 2rem 2rem;
    max-width: unset;
    min-width: unset;
  }

  .options {
    flex-direction: column;
  }

  .container h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .optionContainer {
    width: 100%;
    height: 250px;
    padding: 2rem;
  }

  .optionContainer:first-of-type {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .signUp_brands {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .backArrow {
    cursor: pointer;
    position: relative;
    width: 2rem;
    height: 2rem;
    top: unset;
    left: unset;
    margin-bottom: 2rem;
  }

  .arrow {
    width: 1.25rem;
    height: 1.25rem;
  }

  .container {
    margin-bottom: 9rem;
  }
}