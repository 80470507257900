:root {
  --nextButtonHeight: 5rem;
}

.next_button_container {
  cursor: pointer;
  position: fixed;
  bottom: calc(-1*var(--nextButtonHeight));
  right: 10vw;
  transition: all .3s;
  z-index: 8;
  transform: translateY(0);
  height: var(--nextButtonHeight);
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10rem;
  padding: 1rem 2.5rem;
  background-color: var(--purple);
}

.next_button_container.visible {
  transform: translateY(-10rem);
}

.arrow {
  width: 30px;
  margin-left: 2rem;
}

.loading {
  width: 48px;
  margin-left: 2rem;
}

h4.nextButtonText {
  font-size: 1.25rem;
}

.next_button_container h4.nextButtonText {
  margin-bottom: 0;
}