.brands_grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--tileWidth), 1fr));
  grid-template-rows: auto;
  row-gap: 1.5rem;
  column-gap: 1rem;
  width: 100%;
}

.brands_grid_item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:500px) {
  .brands_grid {
    grid-template-columns: 1fr;
  }
}