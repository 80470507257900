.button {
  font-family: Headline;
  width: fit-content;
  min-height: var(--buttonHeight);
  border-radius: 10rem;
  outline: inherit;
  border: none;
  padding: 0 2rem;
  text-transform: uppercase;
  cursor: pointer;
  background-color: black;
  width: fit-content;
  color: white;
  min-width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSave {
  width: 1.5rem;
  margin-left: 0.5rem;
  margin-right: -0.5rem;
}