@import '../../variables.css';

:root {
  --heroHeight: 100vh;
  --heroTotalHeight: calc(var(--heroHeight) - var(--navHeight));
  --stepsHeight: 12rem;
  --iconSize: 80px;
  --marqueeIconHeight: 90px;
  --brandMarqueeHeight: 140px;
  --landingSaleContainerHeight: 420px;
  --landingBorderRadius: 10px;
}

.hub {}

.titleBig {
  position: relative;
  font-size: 2rem;
  line-height: 2.25rem;
  z-index: 2;
  padding: 0.75rem 1.5rem;
  background-color: black;
  color: white;
  width: fit-content;
  height: fit-content;
}

.titleMedium {
  position: relative;
  color: white;
  padding: 0.5rem 1.25rem;
  background-color: black;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
  line-height: 1.75rem;
  width: fit-content;
  z-index: 2;
  text-align: center;
}

.greyTitle {
  color: var(--darkGrey) !important;
}

.centeredTitle {
  font-size: 4rem;
  line-height: 3.25rem;
}

.contentClassName {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  position: relative;
  width: 100%;
  height: var(--heroTotalHeight);
  min-height: 400px;
  z-index: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module {
  position: relative;
  border-radius: var(--landingBorderRadius);
  /* background-color: black; */
  background-color: var(--mediumGrey);
  padding: 1.75rem 2rem;
}

.heroLeft {
  position: relative;
  width: calc(55% - 0.5rem);
  height: 100%;
  margin-right: 1rem;
  background-color: var(--neon);
}

.info {
  width: 60%;
  height: 100%;
  line-height: 3rem;
  position: relative;
}

.info p {
  background-color: var(--neon);
  color: black;
  padding: 1rem 2rem;
  line-height: 18px;
}

.info button {
  margin-top: 2rem;
}

.info h1 {
  font-size: 3rem;
  line-height: 3rem;
  width: 100%;
  text-align: left;
  padding: 1rem 2rem;
  background-color: black;
  color: white;
}

.info button {
  font-size: 24px;
}

.heroRight {
  width: calc(45% - 0.5rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroRight h2 {
  color: white;
}

.imageBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--landingBorderRadius);
  z-index: 0;
}

.imageBg.imageContain {
  object-fit: contain;
  height: 80%;
  top: 10%;
}

.explainerSection {
  position: relative;
  height: calc(50% - 0.5rem);
  width: 100%;
  background-color: black;
}

.explainerText {
  display: none;
}

.explainerSection:hover .explainer,
.explainerSection:hover .explainerTitle {
  display: none;
}

.explainerSection:hover .explainerText {
  display: flex;
  text-align: center;
  cursor: default;
  color: white;
}

.sale {
}

.drop {
  margin-bottom: 1rem;
}

.section {
  width: 100%;
  padding: 0 1rem 1rem;
}

div.brands {
  width: 100%;
  height: 16rem;
  background-color: black;
  padding: 1.5rem 0 2rem;
}

.brandMarquee {
  width: 100%;
  overflow: hidden;
  z-index: 2;
  padding: 1.25rem 0;
}

.brandIcon {
  min-width: var(--marqueeIconHeight);
  max-width: var(--marqueeIconHeight);
  min-height: var(--marqueeIconHeight);
  max-height: var(--marqueeIconHeight);
  border: 2px solid white;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin-right: 1rem;
}

.how {
  width: 100%;
  height: 28rem;
  background-color: var(--neon);
}

.how h3 {
  background-color: unset;
  color: black;
}

.howSteps {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.step {
  width: 30%;
}

.step h2 {
  width: 85%;
}

.step p {
  background-color: white;
  padding: 1rem 2rem;
  color: black;
  width: 80%;
}

.recentSection {
  height: 80rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recentSection h3 {
  background-color: unset;
  color: black;
}

.recent {
  width: 75%;
  height: 100%;
  margin-right: 1rem;
}

.noPaddingTitle {
  margin: 1.75rem 2rem;
}

.recentSales {
  height: calc(50% - 0.5rem);
  width: 100%;
  padding: 0;
}

.recentDrops {
  height: calc(50% - 0.5rem);
  width: 100%;
  margin-bottom: 1rem;
  padding: 0;
}

.scrollContainer {
  overflow-x: scroll;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.dropCard {
  margin-right: 1rem;
}

.highestSales {
  width: 25%;
  height: 100%;
  /* background-color: black; */
}

.rankedBrands {
  margin: 2rem 0 1rem;
}

.tableHeader {
  margin-bottom: 1rem;
}

.tableHeader P {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--darkGrey);
}

.tableHeader p:first-of-type {
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.rankedSaleContainer {
  margin-bottom: 1rem;
  width: 100%;
}

.salePercentage {
  min-width: 64px;
  min-height: 64px;
  max-width: 64px;
  max-height: 64px;
  border-radius: 50%;
  color: black;
  background-color: white;
  box-sizing: border-box;
  margin-right: 0.5rem;
  font-family: Title;
  font-size: 22px;
}

.why {
  width: 100%;
  height: 40rem;
  background-color: var(--neon);
}

.why h3 {
  background-color: unset;
  color: black;
}

.reasonsContainer {
  width: 85%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whySection {
  width: 30%;
}

.whySection h4 {
  color: white;
  padding: 0.5rem 1.25rem;
  background-color: black;
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.whySection p {
  background-color: white;
  padding: 1rem 2rem;
  color: black;
  width: 80%;
}

.whyBg {
  background-color: black;
  width: 16rem;
  height: 16rem;
  border-radius: 100rem;
  margin-bottom: 2rem;
  object-fit: cover;
}

.whyBg h5 {
  color: white;
}

.featured {
  height: 36rem;
}

.departments {
  height: 26rem;
}

.featuredDrop {
  position: relative;
  width: calc(50% - 0.5rem);
  height: 100%;
  margin-right: 1rem;
  background-color: black;
}

.featuredSale {
  position: relative;
  width: calc(50% - 0.5rem);
  height: 100%;
  background-color: black;
}

.featuredInfo {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  max-width: 90%;
}

.featuredInfo h2 {
  text-align: right;
  background-color: black;
  color: white;
  padding: 0.75rem 1.5rem;
  line-height: 22px;
  font-size: 18px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredBrandContainer {
  height: 3rem;
}

.featuredBrand {
  height: 100%;
  background-color: white;
}

.featuredBrand img {
  max-height: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  min-width: 3rem;
  object-fit: cover;
}

.featuredBrand h4 {
  padding: 0 2rem;
  font-size: 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredInfoTag {
  background-color: var(--purple);
  padding: 0.5rem 1.25rem;
  color: white;
  width: fit-content;
}

.popularBrands {
  width: 25%;
  height: 100%;
  margin-right: 1rem;
}

.editorial {
  width: 75%;
  height: 100%;
}

.editorialPhotos {
  height: calc(50% - 0.5rem);
  width: 100%;
  margin-bottom: 1rem;
}

.activeCoupons {
  height: calc(50% - 0.5rem);
  width: 100%;
}


.mens {
  width: calc(50% - 0.5rem);
  height: 100%;
}

.womens {
  width: calc(50% - 0.5rem);
  height: 100%;
  margin-right: 1rem;
}

.join {
  background-color: var(--neon);
  width: 100%;
  height: 20rem;
}

.join h2 {
  background-color: transparent;
  color: black;
}

.joinContainer {
  margin-top: 4rem;
  height: 4rem;
}

.joinInput {
  height: 100%;
  background-color: white;
  margin-right: 1.5rem;
  border-radius: 3rem;
  padding: 1rem 2rem;
  width: 20rem;
}

.joinButton {
  height: 4rem;
  border-radius: 3rem;
}

@media (max-width:500px) {

  .centeredTitle {
    font-size: 3rem;
    line-height: 2.5rem;
  }

  .hero {
    flex-direction: column;
  }

  .heroLeft {
    width: 100%;
    height: 100%;
    margin-right: 0;
  }

  .heroRight {
    display: none;
  }

  .info {
    width: 85%;
    height: 100%;
  }

  .how {
    height: 68rem;
    background-color: var(--neon);
  }

  .howSteps {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .step {
    width: 100%;
    margin-bottom: 2rem;
  }

  .step h2 {
    width: 95%;
  }

  .step p {
    width: 85%;
  }

  .how h3 {
    line-height: 3.5rem;
  }

  .recentSection {
    height: 162rem;
    flex-direction: column;
  }

  .recent {
    width: 100%;
    height: calc(50% - 0.5rem);
    margin-right: 0;
  }

  .recentSales {
    margin-bottom: 1rem;
  }

  .highestSales {
    width: 100%;
    height: calc(50% - 0.5rem);
  }

  .reasonsContainer {
    width: 100%;
    margin-top: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .featuredDrop {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .featuredSale {
    width: 100%;
  }

  .featured {
    height: 80rem;
  }

  .featuredInfo h2 {
    height: fit-content;
  }

  .why {
    height: 98rem;
  }

  .whySection {
    width: 100%;
    margin-bottom: 2rem;
  }

  .whyBg {
    width: 10rem;
    height: 14rem;
    margin-bottom: 1rem;
  }

  .departments {
    height: 50rem;
  }

  .mens {
    width: 100%;
    height: 100%;
  }

  .womens {
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .join {
    height: 22rem;
  }

  .joinInput {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .joinContainer {
    height: fit-content;
    margin-top: 1rem;
  }

  .featuredBrandContainer {
    height: fit-content;
  }
}