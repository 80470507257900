.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4rem 4rem 10rem 4rem;
  width: 100%;
  min-width: var(--maxPageContentMin);
  max-width: var(--maxPageContent);
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
}

.intro h2 {
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 1rem;
}

.intro p {
  color: var(--darkGrey);
}

.brand_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  align-items: center;
  padding: 4rem;
}

@media (max-width:500px) {
  .content {
    width: 100%;
    padding: 2rem;
    max-width: unset;
    min-width: unset;
  }

  .intro {
    margin-bottom: 2rem;
  }
}