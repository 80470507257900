.signUp_form {
  position: relative;
  min-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.signUp_form input {
  width: 100%;
  margin-bottom: 1rem;
}

.signUp_form label {
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}

.errorMsg {
  position: absolute;
  top: calc(100% + 16px);
  left: 0;
  color: var(--red);
  font-size: 16px;
}

.genderSelect {
  margin-bottom: 1rem;
}

.dropdownCancel {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

.buttonContainer {
  margin-top: 2rem;
}

.modal.buttonContainer {
  margin-top: 4rem;
}

@media (max-width:500px) {
  .signUp_form {
    position: relative;
    min-width: unset;
  }
}