:root {
  --snackbarHeight: 4rem;
}

.notifications_container {
  cursor: pointer;
  position: fixed;
  bottom: calc(-1*var(--snackbarHeight));
  right: 10vw;
  transition: all .3s;
  width: fit-content;
  z-index: 8;
  transform: translateY(0);
  height: var(--snackbarHeight);
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--borderRadius);
  padding: 1rem 2rem;
}

.notifications_container h4 {
  font-size: 18px;
}

.notifications_container.visible {
  transform: translateY(-10rem);
}

.error {
  background-color: red;
  color: white;
}

.warning {
  background-color: var(--orange);
  color: white;
}

.success {
  background-color: var(--purple);
  color: white;
}