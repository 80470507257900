:root {
  --brandContainerWidth: 16rem;
  --couponTileHeight: 5rem;
  --logoWidth: 60px;
}

.baseTile {
  width: 100%;
  height: 100%;
}

.header {}

.brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
}

.brandLogo {
  max-width: var(--logoWidth);
  max-height: var(--logoWidth);
  min-width: var(--logoWidth);
  min-height: var(--logoWidth);
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 1rem;
  object-fit: cover;
}

.brand h4 {
  font-size: 20px;
  font-family: Headline;
}

.date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.date p:first-of-type {
  text-transform: uppercase;
  color: var(--darkGrey);
  margin-right: 0.25rem;
}

.date p {
  font-size: 12px;
}

.container {
  padding: 1rem 0;
}

@media (max-width:500px) {
  .container {
    flex-direction: column;
  }
}